<template>
  <div
    id="userModal"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <form @submit="handleSubmit">
        <div class="modal-content bg-default-dark border-text-default-dark">
          <div class="modal-header">
            <h5 v-if="selectedUser" class="modal-title">
              <b class="text-primary">{{ selectedUser.email }}</b>
            </h5>
            <h5 v-else class="modal-title">Create New User</h5>
          </div>
          <div class="modal-body">
            <div>
              <div
                class="alert alert-danger mb-3"
                v-for="key in Object.keys(errors).filter((k) => errors[k])"
                :key="`error-${key}`"
              >
                {{ errors[key] }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.username }"
                    id="Username"
                    v-model="formData.username"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                    id="Email"
                    v-model="formData.email"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group" v-if="!selectedUser">
                  <label>Password</label>
                  <input
                    type="password"
                    class="form-control"
                    :class="{ 'is-invalid': errors.password }"
                    id="password"
                    v-model="formData.password"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group" v-if="!selectedUser">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    :class="{ 'is-invalid': errors.password }"
                    id="confirmPassword"
                    v-model="formData.confirmPassword"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="inputRole">Role</label>
              <v-select
                :class="{ 'border border-danger rounded': errors.role }"
                v-model="formData.role"
                :options="roles"
              />
            </div>
            <div class="mb-3">
              <label for="inputRegion">Regions</label>
              <v-select
                multiple
                v-model="formData.regions"
                :options="regions"
              />
            </div>
            <div class="mb-3">
              <label for="inputSite">Sites</label>
              <v-select multiple v-model="formData.sites" :options="sites" />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal()"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </form>
    </div>

    <spinner :show="isSaving" text="Saving user..." />
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import Spinner from "@/components/Spinner";

export default {
  name: "modal-user",
  props: ["selectedUser"],
  components: { Spinner },
  data() {
    return {
      roles: [],
      regions: [],
      sites: [],
      formData: {
        username: "",
        email: "",
        role: null,
        regions: [],
        sites: [],
        password: null,
        confirmPassword: null,
      },
      emptyFormData: {
        username: "",
        email: "",
        role: null,
        regions: [],
        sites: [],
        password: null,
        confirmPassword: null,
      },
      errors: {},
      isSaving: false,
    };
  },
  watch: {
    selectedUser: {
      handler() {
        if (this.selectedUser) {
          this.formData = {
            username: this.selectedUser.username,
            email: this.selectedUser.email,
            role: {
              label: this.selectedUser.role.name,
              value: this.selectedUser.role.id,
            },
            regions: this.selectedUser.regions.map((x) => {
              return {
                label: x.name,
                value: x.id,
              };
            }),
            sites: this.selectedUser.sites.map((x) => {
              return {
                label: x.name,
                value: x.id,
              };
            }),
            password: null,
            confirmPassword: null,
          };
        } else {
          this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
        }
      },
    },
  },
  methods: {
    validate() {
      let val = false;
      if (!this.selectedUser) {
        if (!this.formData.password && !this.formData.confirmPassword) {
          this.$set(
            this.errors,
            "password",
            "Your PASSWORD and CONFIRM PASSWORD is required!"
          );
        } else if (!this.formData.password || !this.formData.confirmPassword) {
          this.$set(
            this.errors,
            "password",
            `Your ${
              !this.formData.password
                ? "PASSWORD"
                : !this.formData.confirmPassword
                ? "CONFIRM PASSWORD"
                : ""
            } is required!`
          );
        } else if (this.formData.password != this.formData.confirmPassword) {
          this.$set(
            this.errors,
            "password",
            "Password and Confirm Password must match!"
          );
        } else {
          this.$set(this.errors, "password", "");
          val = true;
        }
      }

      if (!this.formData.role || (val && !this.formData.role)) {
        this.$set(this.errors, "role", "ROLE is required!");
        val = false;
      } else {
        this.$set(this.errors, "role", "");
        val = true;
      }

      if (!this.formData.username || (val && !this.formData.username)) {
        this.$set(this.errors, "username", "USERNAME is required!");
        val = false;
      } else {
        this.$set(this.errors, "username", "");
        val = true;
      }

      if (!this.formData.email || (val && !this.formData.email)) {
        this.errors["email"] = "EMAIL is required!";
        val = false;
      } else {
        this.errors["email"] = "";
        val = true;
      }

      return val;
    },
    async handleSubmit() {
      let payload = {
        ...this.formData,
        role: !this.formData.role ? null : this.formData.role.value,
        regions:
          this.formData.regions.length == 0
            ? []
            : this.formData.regions.map((x) => {
                return x.value;
              }),
        sites:
          this.formData.sites.length == 0
            ? []
            : this.formData.sites.map((x) => {
                return x.value;
              }),
      };

      let method = "post";
      let url = "users";

      if (this.selectedUser) {
        delete payload.password;
        delete payload.confirmPassword;

        method = "put";
        url += `/${this.selectedUser.id}`;
      } else {
        payload = {
          ...payload,
          confirmed: true,
        };
      }

      if (this.validate()) {
        this.isSaving = true;

        const [user, errUser] = await this.Helper.handle(
          this.API[method](url, payload)
        );

        if (!errUser && user.status == 200) {
          this.isSaving = false;

          Swal.fire({
            title: "<h5>User saved</h5>",
            icon: "success",
          }).then(() => {
            this.closeModal(true);
          });
        } else {
          console.error(errUser);

          this.isSaving = false;

          Swal.fire({
            title: "<h5>Error</h5>",
            html: `<div class='line-height-md'>${errUser}</div>`,
            icon: "error",
          });
        }
      }
    },
    closeModal(reload) {
      this.$emit("close");

      if (reload) {
        this.$emit("reload");
      }
    },
    async initData() {
      const [roles, errRoles] = await this.Helper.handle(
        this.API.get(`users-permissions/roles`)
      );
      if (!errRoles && roles.status == 200) {
        this.roles = roles.data.roles.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }

      const [regions, errRegions] = await this.Helper.handle(
        this.API.get(`regions`)
      );
      if (!errRegions && roles.status == 200) {
        this.regions = regions.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }

      const [sites, errSites] = await this.Helper.handle(this.API.get(`sites`));
      if (!errSites && sites.status == 200) {
        this.sites = sites.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
      }
    },
  },
  mounted() {
    this.initData();

    const vm = this;

    $("#userModal").on("hidden.bs.modal", function (event) {
      vm.errors = {};
      vm.formData = JSON.parse(JSON.stringify(vm.emptyFormData));
    });
  },
  beforeDestroy() {
    $("userModal").off("hidden.bs.modal");
  },
};
</script>