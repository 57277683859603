<template>
  <div class="view-fullscreen has-navbar has-footer position-relative py-2">
    <div
      class="d-flex flex-column flex-sm-row justify-content-end align-items-center p-3"
    >
      <div class="input-group mr-sm-3 mb-3 mb-sm-0" style="max-width: 350px">
        <div class="input-group-prepend">
          <span class="input-group-text bg-dark border-secondary">
            <i class="fas fa-search text-secondary" />
          </span>
        </div>
        <input
          type="text"
          class="form-control border-secondary"
          :class="{ 'border-right-0': search.user }"
          placeholder="Search user"
          v-model="search.user"
        />
        <div class="input-group-append" v-if="search.user">
          <button
            class="btn btn-outline-secondary border-left-0"
            type="button"
            @click="search.user = ''"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-dark text-nowrap"
        style="width: 130px"
        @click="visibleModal = true"
      >
        Create New User
      </button>
    </div>
    <div class="p-3" v-if="!isLoading && users.length">
      <table class="table table-bordered table-dark">
        <thead>
          <tr>
            <th>No</th>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Regions</th>
            <th>Sites</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in computedUsers" :key="user.id">
            <td class="w-1">{{ index + 1 }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td class="w-1">{{ user.role.name }}</td>
            <td v-if="user.regions.length">
              <div
                v-for="region in user.regions"
                :key="region.id"
                class="badge badge-dark mx-1"
              >
                {{ region.name }}
              </div>
            </td>
            <td v-else>-</td>
            <td v-if="user.sites.length">
              <div
                v-for="site in user.sites"
                :key="site.id"
                class="badge badge-dark mx-1"
              >
                {{ site.name }}
              </div>
            </td>
            <td v-else>-</td>
            <td class="w-1 text-center">
              <button
                type="button"
                class="btn btn-dark btn-sm text-nowrap"
                @click="selectedUser = user"
              >
                <i class="fas fa-edit mr-2" />
                Edit
              </button>
            </td>
          </tr>
          <tr v-if="!computedUsers.length">
            <td colspan="7" class="text-center text-muted font-italic">
              No user found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="isLoading" class="card m-3">
      <div class="card-body d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
        <span class="ml-3 font-weight-bold h5 mb-0">Loading...</span>
      </div>
    </div>
    <div v-else class="card m-3">
      <div class="card-body text-center">No Content</div>
    </div>
    <user-modal
      @close="visibleModal = false"
      :selectedUser="selectedUser"
      @reload="initData()"
    />
  </div>
</template>

<script>
import UserModal from "../components/UserModal.vue";
export default {
  components: { UserModal },
  name: "user-management-page",
  data() {
    return {
      users: [],
      isLoading: false,
      selectedUser: null,
      visibleModal: false,
      search: {
        user: "",
      },
    };
  },
  watch: {
    selectedUser: {
      handler() {
        if (this.selectedUser) this.visibleModal = true;
      },
    },
    visibleModal: {
      handler() {
        if (this.visibleModal) return this.Helper.showModal("#userModal");
        else {
          this.Helper.hideModal("#userModal");
          this.selectedUser = null;
        }
      },
    },
  },
  computed: {
    computedUsers() {
      return this.search.user
        ? this.users.filter(
            (user) =>
              user.username
                ?.toLowerCase()
                .includes(this.search.user?.toLowerCase()) ||
              user.email
                ?.toLowerCase()
                .includes(this.search.user?.toLowerCase())
          )
        : this.users;
    },
  },
  methods: {
    async initData() {
      this.isLoading = true;
      const [call, err] = await this.Helper.handle(this.API.get(`users`));

      if (!err && call.status == 200) {
        this.users = call.data;
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>